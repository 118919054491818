import axios, { AxiosResponse } from "axios";

import API_URL from "../api";
import Stock from "src/interfaces/stocks";

const stockService = {
  fetchStockData: async (token: string): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${API_URL}Stock/GetAllStocks`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching stock data:", error);
      throw error;
    }
  },
  fetchStockDataBySearchTerm: async (
    searchTerm: any,
    token: string
  ): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${API_URL}Stock/Search?searchTerm=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching stock data:", error);
      throw error;
    }
  },
  fetchStockDataById: async (
    id: number,
    token: string | null
  ): Promise<Stock> => {
    try {
      const response: AxiosResponse<Stock> = await axios.get(
        `${API_URL}Stock/GetStock/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching stock data:", error);
      throw error;
    }
  },

  deleteStockData: async (
    id: number,
    token: string | null
  ): Promise<Stock[]> => {
    try {
      const response: AxiosResponse<Stock[]> = await axios.delete(
        `${API_URL}Stock/DeleteStock/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching stock data:", error);
      throw error;
    }
  },

  importStocks: async (
    userId: number | null,
    insertImport: boolean | undefined,
    file: any,
    token: string | null
  ): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        `${API_URL}Stock/ImportStocks/${userId}/${insertImport}`,
        file,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao enviar email:", error);
      throw error;
    }
  },
  updateStocksQtd: async (
    stockId: number | undefined,
    stockQtd: string | null,
    token: string | null
  ): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.put(
        `${API_URL}Stock/PutAmountStocks/${stockId}/${stockQtd}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao enviar email:", error);
      throw error;
    }
  },
};

export default stockService;
