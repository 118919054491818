import { Navigate } from "react-router-dom";
import Sidebar from "../components/custom/sidebar";
import { useAuth } from "../context";
import { useEffect, useState } from "react";

const ProtectedRoutesAdmin = ({ element, ...rest }: any) => {
  const { isAuthenticated, user } = useAuth();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, [isAuthenticated, user]);

  if (!isReady) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (user?.roleId === "1") {
    return <Sidebar />;
  }


  if (user?.roleId !== "1") {
    const allowedRoutes = ["/titulos"];
    
    if (user?.isFirstAccess.toString() === "true") {
      window.location.href = "/primeiro-acesso";
      return <Navigate to="/primeiro-acesso" />;
    }

    if (!allowedRoutes.includes(window.location.pathname)) {
      window.location.href = "/titulos";
      return <Navigate to="/titulos" />;
    }

    return <Sidebar />;
  }
  return <Navigate to="/titulos" />;
};

export default ProtectedRoutesAdmin;
