import stockService from "src/services/stocks";
import { Loader2, SendHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Skeleton } from "src/components/ui/skeleton";
import Stock from "src/interfaces/stocks";
import emailService from "src/services/email";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { useAuth } from "src/context";
import stockToHTML from "src/helpers/stockToHtml";
import Email from "src/interfaces/email";
import { useToast } from "src/components/ui/use-toast";
import { Toaster } from "src/components/ui/toaster";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/components/ui/alert-dialog";

export function ModalComponent({ id, reloadStocks }: any) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [stock, setStock] = useState<Stock>();
  const [token, setToken] = useState("");
  const [probablyFinantial, setProbablyFinantial] = useState<number | null>(
    null
  );
  const { toast } = useToast();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [shouldSend, setShouldSend] = useState(false);
  const [lowerThan, setLowerThan] = useState("");

  useEffect(() => {}, [stock]);

  const fetchStock = async (id: number) => {
    form.reset();
    resetStates();
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      console.error("Token not found in localStorage");
    }
    await fetchStockById(id, token);
  };

  const fetchStockById = async (id: number, token: string | null) => {
    try {
      var data = await stockService.fetchStockDataById(id, token);
      setStock(data);
    } catch (error) {
      setIsLoading(false);
      console.error("Error ao carregar o titulo:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const sendEmail = async (
    emailData: Email,
    userId: number | null,
    stockId: number | undefined
  ) => {
    await emailService
      .sendEmail(emailData, userId, token, stockId)
      .then((res) => {
        toast({
          variant: "success",
          description:
            user?.roleId === "3"
              ? 'Aplicação em processamento, para confirmar a aplicação responda o e-mail enviado com "Aprovado". Em caso de dúvidas contate seu assessor'
              : "Aplicação enviada com sucesso",
        });
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          description:
            "Houve um erro ao enviar o titulo. Por gentileza, entre em contato com o assessor",
        });
        console.error("Error ao enviar o titulo:", error);
        throw error;
      });
  };

  const updateStock = async (
    stockId: number | undefined,
    stockQtd: string | null
  ) => {
    await stockService.updateStocksQtd(stockId, stockQtd, token);
  };

  const handleConfirmSubmit = () => {
    setShouldSend(true);
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (stock) {
        if (
          probablyFinantial &&
          (stock.financial / stock?.amount) * probablyFinantial < 1000
        ) {
          return;
        }
      }
      if (!shouldSend) {
        setShowConfirmationDialog(true);
        return;
      }

      let stockData = {
        ...stock,
        amount: values.amount,
        financial: stock?.financial,
      };
      var valueCalc =
        stock && probablyFinantial
          ? (stock?.financial / stock?.amount) * probablyFinantial
          : null;
      var body = null;
      // no acesso do cliente
      if (user?.roleId === "3") {
        body = stockToHTML(stockData, valueCalc, user, false);
        let email1 = "gabriel.valadao@kazacapital.com.br";
        let email2 = "herick.otacilio@kazacapital.com.br";
        let email3 = "antonio.pacheco@kazacapital.com.br";
        let hasAdvisor = user?.advisorEmail ? user?.advisorEmail : null;
        let clientEmail = user?.email;
        let values = [email1, email2, email3, clientEmail, hasAdvisor].filter(
          Boolean
        );

        var emailData: Email = {
          to: clientEmail,
          subject: `Nova solicitação de compra de ${user?.name}, ${user?.accountNumber}`,
          body: body,
          cc: values.join(", "),
        };
      } else {
        body = stockToHTML(
          stockData,
          valueCalc,
          user,
          true,
          values.accountNumber
        );
        var emailData: Email = {
          to: values.email,
          subject: `Solicitação de Renda Fixa`,
          body: body,
          cc: `gabriel.valadao@kazacapital.com.br, herick.otacilio@kazacapital.com.br, antonio.pacheco@kazacapital.com.br, ${user?.email}, ${values.email}`,
        };
      }

      setIsSending(true);

      await sendEmail(emailData, user ? user.userId : null, stock?.id);
      await updateStock(stock?.id, values.amount);

      reloadStocks();

      resetStates();
    } catch (error) {
      console.error("Erro ao enviar o titulo:", error);
      resetStates();
    } finally {
      setIsSending(false);
    }
  };

  const resetStates = () => {
    setIsSending(false);
    setShowConfirmationDialog(false);
    setShouldSend(false);
    setProbablyFinantial(null);
    dialogClose();
    setLowerThan("");
  };

  const formSchema = z.object({
    amount: z
      .string({ required_error: "Quantidade é obrigatória" })
      .refine((val) => !Number.isNaN(parseInt(val, 10)), {
        message: "Quantidade deve ser um número",
      }),
    accountNumber: z.string().refine(
      (val: any) => {
        return user?.roleId !== "3" ? val.trim() !== "" : true;
      },
      {
        message: "Número da conta é obrigatório",
      }
    ),
    email: z.string().refine(
      (val: any) => {
        return user?.roleId !== "3" ? val.trim() !== "" : true;
      },
      {
        message: "E-mail é obrigatório",
      }
    ),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      accountNumber: "",
    },
  });

  const dialogClose = () => {
    document.getElementById("closeDialog")?.click();
  };

  const calcFinantial = (value: any = null) => {
    setProbablyFinantial(value);
  };

  useEffect(() => {
    if (stock) {
      if (probablyFinantial !== null) {
        if (probablyFinantial < 0) {
          form.setValue("amount", "");
          setProbablyFinantial(null);
        } else if (probablyFinantial > stock?.amount) {
          form.setValue("amount", stock?.amount.toString());
          setProbablyFinantial(stock?.amount);
        }

        if (
          probablyFinantial &&
          (stock.financial / stock?.amount) * probablyFinantial < 1000
        ) {
          setLowerThan("Aplicação mínima de R$ 1000,00");
        } else {
          setLowerThan("");
        }
      }
    }
  }, [probablyFinantial, stock]);

  useEffect(() => {
    if (shouldSend) {
      form.handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line
  }, [shouldSend]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="text-sm bg-secondary" onClick={() => fetchStock(id)}>
          Enviar <SendHorizontal size={25} className="pl-2" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="text-2xl	"> Enviar titulo</DialogTitle>
        </DialogHeader>
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-6 py-4 px-6">
                <div className="grid grid-cols-1 items-center gap-4">
                  <div>
                    <Label htmlFor="email">Emissor</Label>

                    {isLoading ? (
                      <Skeleton className="h-6 w-[80%] mt-2" />
                    ) : (
                      <p>{stock?.sender}</p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="Data de vencimento">
                      Data de vencimento
                    </Label>
                    {isLoading ? (
                      <Skeleton className="h-6 w-[40%] mt-2" />
                    ) : (
                      <p>
                        {stock
                          ? new Date(stock.dueDate).toLocaleDateString("pt-BR")
                          : null}
                      </p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="Rentabilidade">Rentabilidade</Label>
                    {isLoading ? (
                      <Skeleton className="h-6 w-[40%] mt-2" />
                    ) : (
                      <p>
                        {stock?.indexer} + {stock?.profitability}% a.a
                      </p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="Financeiro">Financeiro</Label>
                    {isLoading ? (
                      <Skeleton className="h-6 w-[50%] mt-2" />
                    ) : (
                      <p>
                        {stock
                          ? Number(stock.financial).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : null}
                      </p>
                    )}
                  </div>
                  <div>
                    <Label htmlFor="Financeiro">Valor de aplicação</Label>
                    {isLoading ? (
                      <Skeleton className="h-6 w-[50%] mt-2" />
                    ) : (
                      <p>
                        {stock
                          ? Number(
                              probablyFinantial &&
                                probablyFinantial.toString() !== "0" &&
                                probablyFinantial > 0
                                ? (stock.financial / stock?.amount) *
                                    probablyFinantial
                                : 0
                            ).toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })
                          : null}
                      </p>
                    )}
                  </div>
                  {user?.roleId !== "3" && (
                    <div>
                      <Label htmlFor="Financeiro">Valor de receita</Label>
                      {isLoading ? (
                        <Skeleton className="h-6 w-[50%] mt-2" />
                      ) : (
                        <p>
                          {stock
                            ? Number(
                                probablyFinantial &&
                                  probablyFinantial.toString() !== "0" &&
                                  probablyFinantial > 0
                                  ? (stock.financial / stock?.amount) *
                                      probablyFinantial *
                                      (stock.roa / 100)
                                  : 0
                              ).toLocaleString("pt-BR", {
                                style: "currency",
                                currency: "BRL",
                              })
                            : null}
                        </p>
                      )}
                    </div>
                  )}
                  <div>
                    <Label htmlFor="amount">
                      Quantidade (max: {stock?.amount})
                    </Label>
                    <FormField
                      control={form.control}
                      name="amount"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div>
                              <Input
                                id="amount"
                                className="w-[200px] mt-2"
                                maxLength={15}
                                placeholder="Quantidade"
                                type="text"
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  calcFinantial(e.target.value);
                                }}
                              />
                            </div>
                          </FormControl>

                          <FormMessage>
                            {lowerThan && (
                              <span className="text-red-500">{lowerThan}</span>
                            )}
                          </FormMessage>
                        </FormItem>
                      )}
                    />
                  </div>
                  {user?.roleId !== "3" && (
                    <div>
                      <Label htmlFor="email">E-mail</Label>
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div>
                                <Input
                                  id="email"
                                  maxLength={60}
                                  placeholder="E-mail para envio"
                                  type="text"
                                  {...field}
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  )}
                  {user?.roleId !== "3" && (
                    <div>
                      <Label htmlFor="accountNumber">Número da conta</Label>
                      <FormField
                        control={form.control}
                        name="accountNumber"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div>
                                <Input
                                  maxLength={60}
                                  type="number"
                                  className="w-[200px]"
                                  placeholder="Número da conta"
                                  {...field}
                                />
                              </div>
                            </FormControl>

                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  )}
                </div>
              </div>
              <DialogFooter>
                <DialogClose asChild>
                  <Button
                    className="text-sm border-primary bg-transparent	border-2 text-primary hover:bg-primary font-medium mt-2"
                    variant="outline"
                  >
                    Cancelar
                  </Button>
                </DialogClose>

                <Button type="submit" className="text-sm bg-secondary mt-2">
                  {isSending && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Enviar
                </Button>

                <AlertDialog
                  open={showConfirmationDialog}
                  defaultOpen={showConfirmationDialog}
                  onOpenChange={setShowConfirmationDialog}
                >
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Confirmação</AlertDialogTitle>
                      <AlertDialogDescription>
                        {user?.roleId === "3"
                          ? "Confirma a aplicação do título nas condições informadas anteriormente?"
                          : "Confirma o envio do título nas condições informadas anteriormente?"}
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        className="bg-secondary"
                        onClick={handleConfirmSubmit}
                      >
                        {isSending && (
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        )}
                        Continuar
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </DialogFooter>
            </form>
          </Form>
        </div>
      </DialogContent>
      <Toaster />
    </Dialog>
  );
}
