/**
 * v0 by Vercel.
 * @see https://v0.dev/t/ZSKwK2qVX2W
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenu,
} from "src/components/ui/dropdown-menu";
import { Banknote, UserRound, FileInput, Mail } from "lucide-react";
import CapitalLogo from "src/assets/kaza-capital.png";
import { useAuth } from "src/context";

export default function Sidebar() {
  const location = useLocation();
  const { logout, user } = useAuth();

  return (
    <div className="grid h-screen w-full min-h-screen lg:grid-cols-[280px_1fr]">
      <div className="hidden bg-gray-100/40 lg:block dark:bg-gray-800/40 bg-tertiary">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-[150px] items-center border-b px-4 py-8 border-tertiary">
            <img src={CapitalLogo} alt="Capital logo" />
          </div>
          <nav className="flex-1 overflow-auto px-4">
            <div className="grid gap-4">
              <Link
                className={`flex items-center gap-3 rounded-lg  hover:bg-tertiary-foreground ${
                  location.pathname === "/titulos"
                    ? "bg-tertiary-foreground"
                    : ""
                } px-3 py-2 text-white`}
                to="/titulos"
              >
                <Banknote className="h-5 w-5" />
                Títulos
              </Link>
              {user?.roleId == "1" && (
                <Link
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 text-gray-500 transition-all text-white hover:bg-tertiary-foreground ${
                    location.pathname === "/usuarios"
                      ? "bg-tertiary-foreground"
                      : ""
                  }`}
                  to="/usuarios"
                >
                  <UserRound className="h-5 w-5" />
                  Usuários
                </Link>
              )}
              {user?.roleId == "1" && (
                <Link
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 text-gray-500 transition-all text-white hover:bg-tertiary-foreground ${
                    location.pathname === "/importacao"
                      ? "bg-tertiary-foreground"
                      : ""
                  }`}
                  to="/importacao"
                >
                  <FileInput className="h-5 w-5" />
                  Importação
                </Link>
              )}
              {user?.roleId == "1" && (
                <Link
                  className={`flex items-center gap-3 rounded-lg  hover:bg-tertiary-foreground ${
                    location.pathname === "/smtp"
                      ? "bg-tertiary-foreground"
                      : ""
                  } px-3 py-2 text-white`}
                  to="/smtp"
                >
                  <Mail className="h-5 w-5" />
                  Configuração SMTP
                </Link>
              )}
            </div>
          </nav>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-14 lg:h-[60px] items-center gap-4 border-b px-6 bg-secondary">
          <Link className="lg:hidden" to="#">
            <span className="sr-only">Home</span>
          </Link>
          <div className="ml-auto w-[200px]">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="flex align-center justify-center text-white cursor-pointer	">
                  <UserRound size={27} className="pr-2 pb-1" />
                  Olá, {user?.name}
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Minha conta</DropdownMenuLabel>
                <DropdownMenuItem onClick={() => logout()}>
                  Sair
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </header>
        <Outlet />
      </div>
    </div>
  );
}
