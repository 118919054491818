import { Input } from "src/components/ui/input";
import {
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from "src/components/ui/table";
import { UserComponent } from "./components/user";
import { useAuth } from "src/context";
import { useEffect, useState } from "react";
import userService from "src/services/users";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "src/components/ui/pagination";
import DeleteUser from "src/components/custom/deleteUser";
import { Loader } from "lucide-react";

export default function Usuarios() {
  const { user } = useAuth();
  const [users, setUsers] = useState<any>([]);
  const rowsPerPage = 10;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(rowsPerPage);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [countAdm, setCountAdm] = useState(0);
  const [countClient, setCountClient] = useState(0);
  const [countAssessor, setCountAssessor] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
      fetchUsersDataWithToken(token);
    } else {
      console.error("Token not found in localStorage");
    }
  }, []);

  const fetchUsersDataWithToken = async (token: string) => {
    try {
      const data = await userService.fetchUserData(
        user ? user.userId : null,
        token
      );
      setUsers(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const countOccurrences = (userId: any) =>
      users.filter((item: any) => item.roleId === userId).length;

    setCountAdm(countOccurrences(1));
    setCountAssessor(countOccurrences(2));
    setCountClient(countOccurrences(3));
  }, [users]);

  const reloadUsers = () => {
    fetchUsersDataWithToken(token);
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setSearchQuery(value);

    if (value.length > 2) {
      userService
        .fetchUsersDataBySearchTerm(value, token)
        .then((res: any) => {
          setUsers(res);
          setStartIndex(0);
          setEndIndex(20);
        })
        .catch((error: any) => {
          console.error("Error fetching user data:", error);
        });
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 8 && searchQuery.length <= 2) {
      fetchUsersDataWithToken(token);
      setStartIndex(0);
      setEndIndex(20);
    }
  };

  return (
    <div className="flex flex-col">
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
        <div className="grid items-center">
          <h1 className="font-semibold text-lg md:text-2xl">Usuários</h1>
          <div className="mb-2 mt-1 "  >
            <span>Totais: </span>
            <p>{countAdm} Administradores</p>
            <p>{countAssessor} Assessores</p>
            <p>{countClient} Clientes</p>
          </div>

          <div className="ml-auto mb-4">
            <Input
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              type="email"
              placeholder="Pesquisar"
            />
          </div>

          <div className="ml-auto mt-4">
            <UserComponent type="create" reloadUsers={reloadUsers} />
          </div>
        </div>
        <div className="border shadow-sm rounded-lg">
          <Table>
            <TableHeader>
              <TableRow className="text-base">
                <TableHead className="pl-6">Nome</TableHead>
                <TableHead className="">E-mail</TableHead>
                <TableHead className="hidden md:table-cell">
                  Permissão
                </TableHead>

                <TableHead className="w-[50px]"></TableHead>
              </TableRow>
            </TableHeader>
            {!isLoading && (
              <TableBody>
                {users
                  .slice(startIndex, endIndex)
                  .map((user: any, key: any) => {
                    return (
                      <TableRow key={key}>
                        <TableCell className="pl-6 font-medium">
                          {user.name}
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          {user.email}
                        </TableCell>

                        <TableCell className="hidden md:table-cell">
                          {
                            ["Administrador", "Assessor", "Cliente"][
                              user.roleId - 1
                            ]
                          }
                        </TableCell>

                        <TableCell className="w-[50px]">
                          <UserComponent
                            userId={user.id}
                            type="edit"
                            reloadUsers={reloadUsers}
                          />
                        </TableCell>
                        <TableCell className="w-[50px]">
                          <DeleteUser id={user.id} reloadUsers={reloadUsers} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
            {isLoading && (
              <TableBody>
                <TableCell colSpan={6}>
                  <div className="h-12 w-[100%] flex items-center	 justify-center">
                    <Loader className="text-center h-7 w-7 animate-spin text-neutral-400" />
                  </div>
                </TableCell>
              </TableBody>
            )}
          </Table>
        </div>
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className={
                  startIndex === 0
                    ? "pointer-events-none opacity-50"
                    : undefined
                }
                onClick={() => {
                  setStartIndex(startIndex - rowsPerPage);
                  setEndIndex(endIndex - rowsPerPage);
                }}
              />
            </PaginationItem>

            <PaginationItem>
              <PaginationNext
                className={
                  endIndex >= users?.length
                    ? "pointer-events-none opacity-50"
                    : undefined
                }
                onClick={() => {
                  setStartIndex(startIndex + rowsPerPage);
                  setEndIndex(endIndex + rowsPerPage);
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </main>
    </div>
  );
}
