import { Edit, Loader2, UserPlus } from "lucide-react";
import { Button } from "src/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/components/ui/dialog";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import userService from "src/services/users";
import { useEffect, useState } from "react";
import { useToast } from "src/components/ui/use-toast";
import { useAuth } from "src/context";
import { Toaster } from "src/components/ui/toaster";

export function UserComponent({ type, userId, reloadUsers }: any) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [token, setToken] = useState("");
  const { toast } = useToast();
  const [advisor, setAdvisor] = useState<any>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [shouldFillAdvisor, setShouldFillAdvisor] = useState<any>(null);
  const [showAdvisor, setShowAdvisor] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);

  const fetchUserData = async (userId: number) => {
    try {
      const data = await userService.fetchUserById(userId, token);
      form.reset({
        ...data,
        password: "",
        advisorId: data.advisorId ? data.advisorId.toString() : null,
      });
      setUserData(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (userData && advisor) {
      form.setValue(
        "advisorId",
        userData.advisorId ? userData.advisorId.toString() : ""
      );
    }
  }, [userData, advisor]);

  const fetchAdvisors = async (userId: number) => {
    try {
      const data = await userService.fetchAdvisorsByUserId(userId, token);

      setAdvisor(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const updateUserData = async (data: any) => {
    let newData = {
      ...data,
      password: null,
    };
    await userService
      .editUser(
        newData,
        user ? user.userId : null,
        userId ? userId : null,
        token
      )
      .then((res) => {
        setIsLoading(false);
        toast({
          variant: "success",
          description: "Usuário atualizado com sucesso",
        });
        reloadUsers();
        dialogClose();
      })
      .catch((error) => {
        setIsLoading(false);
        toast({
          variant: "destructive",
          description: "Houve um erro ao editar o usuário",
        });
      })
      .finally(() => {
        resetStates();
      });
  };

  const createUserData = async (data: any) => {
    await userService
      .createUser(user ? user.userId : null, data, token)
      .then((res) => {
        setIsLoading(false);
        toast({
          variant: "success",
          description: "Usuário cadastrado com sucesso",
        });

        reloadUsers();
        dialogClose();
      })
      .catch((error) => {
        if (error) {
          setIsLoading(false);
          toast({
            variant: "destructive",
            description: "Houve um erro ao cadastrar o usuário",
          });
        }
      })
      .finally(() => {
        resetStates();
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchAdvisors(user ? user.userId : 0);
      setShouldFillAdvisor(null);
    }
  }, [isModalOpen]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      console.error("Token not found in localStorage");
    }
  }, []);

  const formSchema = z.object({
    name: z.string().min(1, {
      message: "Nome é obrigatório",
    }),
    email: z
      .string()
      .min(1, {
        message: "E-mail é obrigatório",
      })
      .email({
        message: "E-mail inválido",
      }),
    password: z.string().refine(
      (val: any) => {
        return type === "create" ? val.trim() !== "" : true;
      },
      {
        message: "Senha é obrigatória",
      }
    ),
    roleId: z.string({ required_error: "Permissão é obrigatória" }),
    accountNumber: z
      .string({ required_error: "Número da conta é obrigatório" })
      .refine((val) => !Number.isNaN(parseInt(val, 10)), {
        message: "A conta deve ser somente números",
      }),
    advisorId: z.string().optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      name: "",
      roleId: "3",
      accountNumber: "",
      advisorId: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (values.roleId === "3" && values.advisorId === "") {
      setShouldFillAdvisor("Assessor é obrigatório");
      return;
    } else {
      setShouldFillAdvisor(null);
    }
    var formValues = {
      ...values,
      isFirstAccess: type === "create" ? true : userData?.firstAccess,
      roleId: parseInt(values.roleId),
      id: type === "edit" ? userId : 0,
      advisorId: values.advisorId ? parseInt(values.advisorId) : null,
    };
    setIsLoading(true);

    if (type === "create") {
      createUserData(formValues);
    } else {
      updateUserData(formValues);
    }
    dialogClose();
  }

  const dialogClose = () => {
    document.getElementById("closeDialog")?.click();
  };

  const handleOpen = () => {
    setModalOpen(!isModalOpen);
  };

  const handleRoleChange = (value: any) => {
    if (value !== "3") {
      setShowAdvisor(true);
    } else {
      setShowAdvisor(false);
    }
  };

  const resetStates = () => {
    setIsLoading(false);
    setShouldFillAdvisor(null);
    setShowAdvisor(false);
    setAdvisor([]);
  };

  return (
    <Dialog onOpenChange={() => handleOpen()}>
      <DialogTrigger asChild>
        {type === "edit" ? (
          <Button variant="outline" onClick={() => fetchUserData(userId)}>
            <Edit size={20} />
          </Button>
        ) : (
          <Button
            className="bg-secondary hover:bg-secondary-foreground"
            onClick={() => form.reset()}
          >
            Cadastrar usuário <UserPlus className="pl-2" size={25} />
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle className="text-2xl	">
            {type === "edit" ? "Editar Usuário" : "Cadastrar Usuário"}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid  grid-cols-1 gap-6 py-4 px-6">
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <Label htmlFor="nome">Nome</Label>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div>
                            <Input
                              maxLength={60}
                              type="text"
                              placeholder="Nome do usuário"
                              {...field}
                            />
                          </div>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <Label htmlFor="permissão">Selecione a permissão</Label>
                  <FormField
                    control={form.control}
                    name="roleId"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div>
                            <Select
                              {...field}
                              onValueChange={(value) => {
                                handleRoleChange(value);
                                field.onChange(value);
                              }}
                              defaultValue={field.value}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Selecione" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup>
                                  <SelectLabel>Permissões</SelectLabel>
                                  <SelectItem value="1">
                                    Administrador
                                  </SelectItem>
                                  <SelectItem value="2">Assessor</SelectItem>
                                  <SelectItem value="3">Cliente</SelectItem>
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </div>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {!showAdvisor && (
                  <div>
                    <Label htmlFor="permissão">Selecione o assessor</Label>
                    <FormField
                      control={form.control}
                      name="advisorId"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div>
                              <Select
                                {...field}
                                onValueChange={field.onChange}
                                defaultValue={field.value}
                              >
                                <SelectTrigger>
                                  <SelectValue placeholder="Selecione" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup>
                                    <SelectLabel>Assessor</SelectLabel>
                                    {advisor?.map((adv: any) => (
                                      <SelectItem
                                        key={adv.userId}
                                        value={adv.userId.toString()}
                                      >
                                        {adv.name}
                                      </SelectItem>
                                    ))}
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                            </div>
                          </FormControl>

                          <FormMessage>
                            {shouldFillAdvisor && (
                              <span className="text-red-500">
                                {shouldFillAdvisor}
                              </span>
                            )}
                          </FormMessage>
                        </FormItem>
                      )}
                    />
                  </div>
                )}
                <div>
                  <Label htmlFor="accountNumber">Número da conta</Label>
                  <FormField
                    control={form.control}
                    name="accountNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div>
                            <Input
                              maxLength={60}
                              type="number"
                              placeholder="Número da conta"
                              {...field}
                            />
                          </div>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <Label htmlFor="email">E-mail</Label>
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div>
                            <Input
                              id="email"
                              maxLength={60}
                              placeholder="E-mail do usuário"
                              type="text"
                              {...field}
                            />
                          </div>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                {type === "create" && (
                  <div>
                    <Label htmlFor="senha">Senha</Label>
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div>
                              <Input
                                type="password"
                                maxLength={60}
                                placeholder="Senha"
                                {...field}
                              />
                            </div>
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  className="text-sm border-primary bg-transparent	border-2 text-primary hover:bg-primary font-medium mt-2"
                  variant="outline"
                >
                  Cancelar
                </Button>
              </DialogClose>
              <Button type="submit" className="text-sm bg-secondary mt-2">
                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Salvar
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
      <Toaster />
    </Dialog>
  );
}
