import axios, { AxiosResponse } from "axios";

import API_URL from "../api";
import Email from "src/interfaces/email";

const emailService = {
  sendEmail: async (
    emailData: Email,
    userId: number | null,
    token: string,
    stockId: number | undefined
  ): Promise<Email> => {
    try {
      const response: AxiosResponse<Email> = await axios.post(
        `${API_URL}Email/SendEmail`,
        emailData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao enviar email:", error);
      throw error;
    }
  },

  createSMTP: async (
    userId: number | null,
    email: string,
    password: string,
    smtpUrl: string,
    port: number,
    token: string
  ): Promise<Email> => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        `${API_URL}Email/CreateSMTP/${userId}`,
        { email, password, smtpUrl, port },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao cadastrar titulo:", error);
      throw error;
    }
  },

  editSMTP: async (
    smtpId: number | null,
    email: string,
    password: string,
    smtpUrl: string,
    port: number,
    token: string,
    userId: number | null,
  ): Promise<Email> => {
    try {
      const response: AxiosResponse<any> = await axios.put(
        `${API_URL}Email/EditSMTP/${smtpId}?userId=${userId}`,
        { email, password, smtpUrl, port },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao editar smpt:", error);
      throw error;
    }
  },

  fetchEmailSMTPById: async (
    userId: number | null,
    token: string | null
  ): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${API_URL}Email/GetSMTP`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching email data:", error);
      throw error;
    }
  },
};

export default emailService;
