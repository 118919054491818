/**
 * v0 by Vercel.
 * @see https://v0.dev/t/ZSKwK2qVX2W
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import stockService from "src/services/stocks";
import { Loader2, Trash2 } from "lucide-react";
import { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "src/components/ui/alert-dialog";
import { Button } from "src/components/ui/button";
import { useToast } from "src/components/ui/use-toast";
import { Toaster } from "src/components/ui/toaster";

export default function DeleteAction({ id, reloadStocks }: any) {
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { toast } = useToast();

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      setIsDeleting(true);
      await deleteStock(id, token);
      reloadStocks();
      setDeleteId(null);
      toast({
        variant: "success",
        description: "Título excluído com sucesso",
      });
    } catch (error) {
      console.error("Erro ao excluir registro:", error);
    } finally {
      setIsDeleting(false);
    }
  };
  const shouldDelete = (id: number) => {
    setDeleteId(id);
  };

  const deleteStock = async (id: number, token: string | null) => {
    try {
      await stockService.deleteStockData(id, token);
    } catch (error) {
      console.error("Error deleting stock data:", error);
    }
  };

  return (
    <>
      <AlertDialog open={deleteId === id}>
        <AlertDialogTrigger asChild>
          <Button variant="outline" onClick={() => shouldDelete(id)}>
            <Trash2 size={20} />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Tem certeza que deseja excluir esse registro?
            </AlertDialogTitle>
            <AlertDialogDescription>
              Essa ação não pode ser desfeita.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setDeleteId(null)}>
              Cancelar
            </AlertDialogCancel>
            <AlertDialogAction
              className="bg-danger hover:bg-danger-foreground"
              onClick={() => handleDelete()}
            >
              {isDeleting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Excluir
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Toaster />
    </>
  );
}
