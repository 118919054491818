import axios, { AxiosResponse } from "axios";

import API_URL from "../api";

const userService = {
  fetchUserData: async (userId: number | null, token: string): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${API_URL}User/GetAllUsers/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  },
  fetchUserById: async (userId: number | null, token: string): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${API_URL}User/GetUser/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  },

  fetchUsersDataBySearchTerm: async (
    searchTerm: any,
    token: string
  ): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${API_URL}User/Search?query=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching stock data:", error);
      throw error;
    }
  },

  fetchAdvisorsByUserId: async (userId: number | null, token: string): Promise<any> => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `${API_URL}User/GetAllAdvisors/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  },

  createUser: async (
    userId: number | null,
    form: any,
    token: string | null
  ): Promise<string> => {
    try {
      const response: AxiosResponse<string> = await axios.post(
        `${API_URL}User/CreateUser/${userId}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao cadastrar usuário:", error);
      throw error;
    }
  },

  editUser: async (form: any, loggedUser: number | null, userId: number | null, token: string | null): Promise<string> => {
    try {
      const response: AxiosResponse<string> = await axios.put(
        `${API_URL}User/EditUser/${loggedUser}/${userId}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      throw error;
    }
  },

  editUserPassword: async (form: any, userId: number | null, token: string | null): Promise<string> => {
    try {
      const response: AxiosResponse<string> = await axios.put(
        `${API_URL}User/EditUserPassword/${userId}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      throw error;
    }
  },

  deleteUser: async (
    userId: number | null,
    idDeleted: number | null,
    token: string | null
  ): Promise<string> => {
    try {
      const response: AxiosResponse<string> = await axios.delete(
        `${API_URL}User/DeleteUser/${idDeleted}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao deletar usuário:", error);
      throw error;
    }
  },
};

export default userService;
