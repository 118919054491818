import { Input } from "src/components/ui/input";
import {
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from "src/components/ui/table";
import { ModalComponent } from "src/pages/titulos/components/sendEmailComponent";
import DeleteAction from "src/components/custom/deleteStock";
import { useEffect, useState } from "react";
import stockService from "src/services/stocks";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "src/components/ui/pagination";
import { useAuth } from "src/context";
import useSortableTable from "src/helpers/useSortableTable";
import { ArrowUpDown, Loader } from "lucide-react";

export default function Titulos() {
  const { user } = useAuth();
  const [stocks, setStocks] = useState<any>([]);
  const rowsPerPage = 20;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(rowsPerPage);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { sortedData, requestSort } = useSortableTable(stocks);
  const [stockMessage, setStockMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
      fetchStockDataWithToken(token);
    } else {
      console.error("Token not found in localStorage");
    }
  }, []);

  const fetchStockDataWithToken = async (token: string) => {
    try {
      const data = await stockService.fetchStockData(token);
      setStocks(data.stocks);
      setIsLoading(false);
      if (data.stocks.length === 0) {
        setStockMessage("Ainda não há títulos cadastrados");
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching stock data:", error);
    }
  };

  const reloadStocks = () => {
    fetchStockDataWithToken(token);
  };

  const handleChange = (event: any) => {
    const { value } = event.target;
    setSearchQuery(value);

    if (value.length > 2) {
      stockService
        .fetchStockDataBySearchTerm(value, token)
        .then((res) => {
          setStocks(res);
          setStartIndex(0);
          setEndIndex(20);
        })
        .catch((error) => {
          console.error("Error fetching stock data:", error);
        });
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 8 && searchQuery.length <= 2) {
      fetchStockDataWithToken(token);
      setStartIndex(0);
      setEndIndex(20);
    }
  };

  return (
    <div className="flex flex-col">
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
        <div className="grid items-center">
          <h1 className="font-semibold text-lg md:text-2xl">Títulos</h1>
          <div className="ml-auto">
            <Input
              type="text"
              placeholder="Pesquisar"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className="border shadow-sm rounded-lg">
          <Table>
            <TableHeader>
              <TableRow className="text-base">
                <TableHead className="w-[150px] text-center">Enviar</TableHead>
                {user?.roleId === "1" && (
                  <TableHead className="w-[100px] text-center">
                    <span className="flex items-center justify-center">ID</span>
                  </TableHead>
                )}
                <TableHead
                  className="max-w-[150px] cursor-pointer	"
                  onClick={() => requestSort("sender")}
                >
                  <span className="flex  items-center	 justify-left">
                    Emissor
                    <ArrowUpDown className="size-4 ml-2" />
                  </span>
                </TableHead>
                <TableHead
                  className="hidden md:table-cell cursor-pointer"
                  onClick={() => requestSort("dueDate")}
                >
                  <span className="flex  items-center	 justify-left">
                    Vencimento
                    <ArrowUpDown className="size-4 ml-2" />
                  </span>
                </TableHead>

                <TableHead
                  className="cursor-pointer"
                  onClick={() => requestSort("profitability")}
                >
                  <span className="flex  items-center	 justify-left">
                    Rentabilidade
                    <ArrowUpDown className="size-4 ml-2" />
                  </span>
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => requestSort("amount")}
                >
                  <span className="flex  items-center	 justify-left">
                    Quantidade
                    <ArrowUpDown className="size-4 ml-2" />
                  </span>
                </TableHead>
                <TableHead
                  className="cursor-pointer"
                  onClick={() => requestSort("financial")}
                >
                  <span className="flex  items-center	 justify-left">
                    Financeiro
                    <ArrowUpDown className="size-4 ml-2" />
                  </span>
                </TableHead>
                {user?.roleId !== "3" && (
                  <TableHead
                    className="text-center cursor-pointer"
                    onClick={() => requestSort("roa")}
                  >
                    <span className="flex items-center justify-center">
                      ROA
                      <ArrowUpDown className="size-4 ml-2" />
                    </span>
                  </TableHead>
                )}
                {user?.roleId === "1" && (
                  <TableHead className="w-[50px]"></TableHead>
                )}
              </TableRow>
            </TableHeader>
            {!isLoading && (
              <TableBody>
                {sortedData
                  .slice(startIndex, endIndex)
                  .map((stock: any, key: any) => {
                    const formattedDate = new Date(
                      stock.dueDate
                    ).toLocaleDateString("pt-BR");
                    const formattedFinancial = Number(
                      stock.financial
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    });

                    const profitabilityFormatted =
                      stock.profitability == 0
                        ? ""
                        : `+ ${stock.profitability}%`;

                    return (
                      <TableRow key={key}>
                        <TableCell className="text-center">
                          {!stock.isSoldOut ? (
                            <ModalComponent
                              id={stock.id}
                              reloadStocks={reloadStocks}
                            />
                          ) : (
                            <span className="text-primary">Esgotado</span>
                          )}
                        </TableCell>
                        {user?.roleId === "1" && (
                          <TableCell className="text-center">
                            {stock.identifier}
                          </TableCell>
                        )}
                        <TableCell className="font-medium">
                          {stock.sender}
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          {formattedDate}
                        </TableCell>
                        <TableCell>
                          {stock.indexer} {profitabilityFormatted}
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          {stock.amount}
                        </TableCell>
                        <TableCell className="hidden md:table-cell">
                          {formattedFinancial}
                        </TableCell>
                        {user?.roleId !== "3" && (
                          <TableCell className="text-center">
                            {stock.roa}
                          </TableCell>
                        )}

                        {user?.roleId === "1" && (
                          <TableCell className="w-[50px]">
                            <DeleteAction
                              id={stock.id}
                              reloadStocks={reloadStocks}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            )}
            {isLoading && (
              <TableBody className="h-[60px]">
                <TableRow>
                  <TableCell colSpan={8} className="h-[60px]">
                    <div className="h-12 max-h-[36px] w-[100%] flex items-center	 justify-center">
                      <Loader className="text-center h-7 w-7 animate-spin text-neutral-400" />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>

          {sortedData.length === 0 ? (
            <p className="w-[100%] p-4 text-center">{stockMessage}</p>
          ) : null}
        </div>
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className={
                  startIndex === 0
                    ? "pointer-events-none opacity-50"
                    : undefined
                }
                onClick={() => {
                  setStartIndex(startIndex - rowsPerPage);
                  setEndIndex(endIndex - rowsPerPage);
                }}
              />
            </PaginationItem>

            <PaginationItem>
              <PaginationNext
                className={
                  endIndex >= stocks.length
                    ? "pointer-events-none opacity-50"
                    : undefined
                }
                onClick={() => {
                  setStartIndex(startIndex + rowsPerPage);
                  setEndIndex(endIndex + rowsPerPage);
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </main>
    </div>
  );
}
