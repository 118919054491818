import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Loader2 } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useAuth } from "src/context";
import { useEffect, useState } from "react";
import emailService from "src/services/email";
import { useToast } from "src/components/ui/use-toast";
import { Toaster } from "src/components/ui/toaster";

export default function SMTP() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [smtpId, setSmtpId] = useState<number | null>(null);
  const [token, setToken] = useState("");
  const { toast } = useToast();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
      fetchEmailSmtp(token);
    } else {
      console.error("Token not found in localStorage");
    }
  }, []);

  const fetchEmailSmtp = async (token: string) => {
    try {
      const data = await emailService.fetchEmailSMTPById(
        user ? user.userId : null,
        token
      );
      form.reset({ ...data, password: "", port: data.port.toString() });
      setSmtpId(data.id);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.error("Error fetching email data:", error);
    }
  };

  const formSchema = z.object({
    email: z
      .string()
      .min(1, {
        message: "E-mail é obrigatório",
      })
      .email({
        message: "E-mail inválido",
      }),
    password: z.string().min(1, {
      message: "Senha é obrigatória",
    }),
    smtpUrl: z.string().min(1, {
      message: "URL SMTP é obrigatório",
    }),
    port: z
      .string({ required_error: "Porta é obrigatória" })
      .refine((val) => !Number.isNaN(parseInt(val, 10)), {
        message: "Porta deve ser um número",
      }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      smtpUrl: "",
      port: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);

    var port = values.port;
    if (!smtpId) {
      emailService
        .createSMTP(
          user ? user.userId : null,
          values.email,
          values.password,
          values.smtpUrl,
          parseInt(port),
          token
        )
        .then((res) => {
          setIsLoading(false);
          toast({
            variant: "success",
            description: "Configuração SMTP salva com sucesso",
          });
        })
        .catch((error) => {
          setIsLoading(false);
          toast({
            variant: "destructive",
            description: "Houve um erro ao cadastrar a configuração SMTP",
          });
        });
    } else {
      emailService
        .editSMTP(
          smtpId,
          values.email,
          values.password,
          values.smtpUrl,
          parseInt(port),
          token,
          user ? user.userId : null
        )
        .then((res) => {
          setIsLoading(false);
          toast({
            variant: "success",
            description: "Configuração SMTP atualizada com sucesso",
          });
        })
        .catch((error) => {
          setIsLoading(false);
          toast({
            variant: "destructive",
            description: "Houve um erro ao cadastrar a configuração SMTP",
          });
        });
    }
  }

  return (
    <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
      <div className="grid items-center">
        <h1 className="font-semibold text-lg md:text-2xl">Configuração SMTP</h1>
      </div>

      <div className="w-full max-w-lg">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col space-y-6"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div>
                      <Input
                        id="email"
                        maxLength={60}
                        placeholder="E-mail"
                        type="text"
                        {...field}
                      />
                    </div>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div>
                      <Input  maxLength={60} type="password" placeholder="Senha" {...field} />
                    </div>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="port"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div>
                      <Input  maxLength={60} type="number" placeholder="Porta" {...field} />
                    </div>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="smtpUrl"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div>
                      <Input  maxLength={60} type="text" placeholder="Url SMTP" {...field} />
                    </div>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="w-full border-primary bg-transparent	border-2 text-primary hover:bg-primary font-medium"
              type="submit"
              variant="outline"
            >
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Enviar configuração SMTP
            </Button>
          </form>
        </Form>
        <Toaster />
      </div>
    </main>
  );
}
