import {
  useState,
  useEffect,
  createContext,
  useContext,
  ReactNode,
} from "react";

interface User {
  name: string;
  email: string;
  roleId: string;
  userId: number;
  isFirstAccess: boolean;
  accountNumber: string;
  advisorId: string;
  advisorEmail: string;
}

interface AuthContextType {
  isAuthenticated: boolean;
  authenticate: (params: string) => void;
  logout: () => void;
  user: User | null;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  authenticate: () => {},
  logout: () => {},
  user: null,
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState(Object);

  useEffect(() => {}, [isAuthenticated, user]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const roleId = localStorage.getItem("roleId");
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const userId = localStorage.getItem("userId");
    const isFirstAccess = localStorage.getItem("isFirstAccess");
    const accountNumber = localStorage.getItem("accountNumber");
    const advisorEmail = localStorage.getItem("advisorEmail");
    if (token) {
      setUser({
        name: name || "",
        email: email || "",
        roleId: roleId || "",
        userId: userId || "",
        isFirstAccess: isFirstAccess || "",
        accountNumber: accountNumber || "",
        advisorEmail: advisorEmail || "",
      });
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      if (!localStorage.getItem("roleId")) {
        setUser(null);
        return;
      }

      const userRoleId = localStorage.getItem("roleId");

      if (userRoleId !== user.roleId) {
        logout();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [user]);

  const authenticate = (params: any) => {
    try {
      const {
        token,
        roleId,
        name,
        email,
        userId,
        isFirstAccess,
        accountNumber,
        advisorEmail,
      } = params;
      setUser({
        name,
        email,
        roleId,
        userId,
        isFirstAccess,
        accountNumber,
        advisorEmail,
      });
      localStorage.setItem("token", token);
      localStorage.setItem("roleId", roleId);
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("userId", userId);
      localStorage.setItem("isFirstAccess", isFirstAccess);
      localStorage.setItem("accountNumber", accountNumber);
      localStorage.setItem("advisorEmail", advisorEmail);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Erro ao autenticar:", error);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, authenticate, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
