"use client";

import "./styles.css";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Logo from "src/assets/kaza-capital.png";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import authService from "src/services/auth";
import { useAuth } from "src/context";
import { Loader2 } from "lucide-react";
import { useState } from "react";

export function Login() {
  const { authenticate } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formSchema = z.object({
    email: z
      .string()
      .min(1, {
        message: "E-mail é obrigatório",
      })
      .email({
        message: "E-mail inválido",
      }),
    password: z.string().min(1, {
      message: "Senha é obrigatória",
    }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    authService
      .login(values.email, values.password)
      .then((token) => {
        authenticate(token);
        setIsLoading(false);
        window.location.href="/titulos";
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          form.setError("email", {
            type: "custom",
            message: "E-mail ou senha inválidos",
          });
        }
        setIsLoading(false);
      }).finally(() =>{
        setIsLoading(false);
      });
  }
  return (
    <>
      <main className="bg-gradient-to-l from-[#0B3168] to-[#03060B] dark:from-[#0B3168] dark:to-[#03060B] min-h-screen flex items-center justify-center">
        <div className="w-full max-w-lg mx-auto  p-6 space-y-4 rounded-lg  dark:bg-gray-900 h-[600px]">
          <div className="flex justify-center items-center">
            <img
              src={Logo}
              alt="Kaza Capital - BTG"
              className="max-w-[300px]"
            />
          </div>
          <h1 className="text-2xl font-medium text-center mb-4 text-white pb-10">
            Entre na sua conta
          </h1>
          <div className="space-y-8">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-6"
              >
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div>
                          <Input
                            id="email"
                            maxLength={60}
                            className="text-white"
                            placeholder="E-mail"
                            type="text"
                            {...field}
                          />
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div>
                          <Input
                            type="password"
                            maxLength={60}
                            placeholder="Senha"
                            className="text-white"
                            {...field}
                          />
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  className="w-full border-primary bg-transparent	border-2 text-primary hover:bg-primary font-medium"
                  type="submit"
                  variant="outline"
                >
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Entrar
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </main>
    </>
  );
}
