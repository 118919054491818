import { createBrowserRouter } from "react-router-dom";
import { Home } from "src/pages/home";
import {Login} from "../pages/login";
import { PrimeiroAcesso } from "../pages/primeiroAcesso";
import Usuarios from "../pages/usuarios";
import Importacao from "../pages/importacao";
import Titulos from "../pages/titulos";
import ProtectedRoutesAdmin from "./privateRouteAdmin";
import SMTP from "../pages/smtp";

const AllRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/primeiro-acesso",
    element: <PrimeiroAcesso />,
  },
  {
    element: <ProtectedRoutesAdmin />,
    children: [
      {
        path: "/titulos",
        element: <Titulos />,
      },
      {
        path: "/smtp",
        element: <SMTP />,
      },
      {
        path: "/usuarios",
        element: <Usuarios />,
      },

      {
        path: "/importacao",
        element: <Importacao />,
      },
      
    ],
  },
]);

export default AllRoutes;
