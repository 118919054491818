"use client";

import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Logo from "src/assets/kaza-capital.png";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/components/ui/form";
import { useAuth } from "src/context";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import userService from "src/services/users";
import { useToast } from "src/components/ui/use-toast";

export function PrimeiroAcesso() {
  const { user } = useAuth();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const formSchema = z
    .object({
      password: z.string().min(1, {
        message: "Senha é obrigatória",
      }),
      confirm: z.string().min(1, {
        message: "Confirme a senha",
      }),
    })
    .refine((data) => data.password === data.confirm, {
      message: "As senhas não conferem",
      path: ["confirm"],
    });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirm: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true);
    let data = {
      password: values.password,
      isFirstAccess: false
    };
    const token = localStorage.getItem("token");
    await userService
      .editUserPassword(data, user ? user.userId : null, token)
      .then((res) => {
        setIsLoading(false);
        toast({
          variant: "success",
          description: "Senha alterada com sucesso",
        });
        setIsLoading(false);
        localStorage.setItem("isFirstAccess", "false");
        window.location.href="/titulos";
      })
      .catch((error) => {
        setIsLoading(false);
        toast({
          variant: "destructive",
          description: "Houve um erro ao atualizar a senha",
        });
      });
  };
  return (
    <>
      <main className="bg-gradient-to-l from-[#0B3168] to-[#03060B] dark:from-[#0B3168] dark:to-[#03060B] min-h-screen flex items-center justify-center">
        <div className="w-full max-w-lg mx-auto  p-6 space-y-4 rounded-lg  dark:bg-gray-900 h-[600px]">
          <div className="flex justify-center items-center">
            <img
              src={Logo}
              alt="Kaza Capital - BTG"
              className="max-w-[300px]"
            />
          </div>
          <h1 className="text-2xl font-medium text-center mb-2 text-white pb-2">
            Olá, identificamos que este é o seu primeiro acesso
          </h1>
          <h5 className="text-sm font-medium text-left text-white">
            Por favor, crie uma nova senha para acessar o sistema
          </h5>
          <div className="space-y-8">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="flex flex-col space-y-6"
              >
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div>
                          <Input
                            type="password"
                            maxLength={60}
                            placeholder="Senha"
                            className="text-white"
                            {...field}
                          />
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="confirm"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div>
                          <Input
                            type="password"
                            maxLength={60}
                            placeholder="Confirme a senha"
                            className="text-white"
                            {...field}
                          />
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  className="w-full border-primary bg-transparent	border-2 text-primary hover:bg-primary font-medium"
                  type="submit"
                  variant="outline"
                >
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Alterar senha
                </Button>
              </form>
            </Form>
          </div>
        </div>
      </main>
    </>
  );
}
