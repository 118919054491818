import axios, { AxiosResponse } from "axios";

import API_URL from "../api";

const authService = {
  login: async (email: string, password: string): Promise<string> => {
    try {
      const response: AxiosResponse<string> = await axios.post(
        `${API_URL}Authentication/login`,
        { email, password }
      );
      return response.data;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      throw error;
    }
  },
};

export default authService;
