"use client";

import * as React from "react";
import { Link } from "react-router-dom";
import { Button } from "src/components/ui/button";
import Logo from "src/assets/kaza-capital-home.png";

import "./styles.css";

export function Home() {
  const [state, setState] = React.useState(false);

  return (
    <div>
      <header className="flex h-30 w-full items-center px-6 md:px-24 md:py-3 py-2">
        <div>
          <Link to="#">
            <img src={Logo} className="max-w-[200px]" />
          </Link>
        </div>
        <div className="ml-auto">
          <Link
            className="inline-flex h-9 items-center justify-center rounded-md px-8 py-5 text-sm bg-secondary text-white hover:bg-secondary-foreground font-normal"
            to="/login"
          >
            Entrar
          </Link>
        </div>
      </header>
      <section className="w-full h-screen bg-[url('/src/assets/kaza-back.png')] bg-cover bg-center flex">
        <div className="container md:pt-16 md:pl-24 text-center text-left  h-[600px] flex items-start justify-center flex-col">
          <h1 className=" font-medium text-3xl sm:text-4xl md:text-6xl text-white text-left">
            Revolucione o seu jeito de investir
          </h1>
          <p className="text-white text-left pt-6 text-lg">
            Seu patrimônio rende mais quando investido nos lugares certos
          </p>
          <div className="mt-8 text-left">
            <Link to="https://kazacapital.com.br/">
              <Button
                variant="outline"
                className=" border-primary bg-transparent	border-2 text-primary hover:bg-primary font-medium rounded-full text-sm sm:text-md md:text-lg p-6"
              >
                Saiba mais
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
