import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { FileSpreadsheet, Loader2 } from "lucide-react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/components/ui/form";
import { useState } from "react";
import stockService from "src/services/stocks";
import { useAuth } from "src/context";
import { useToast } from "src/components/ui/use-toast";
import { Toaster } from "src/components/ui/toaster";
import { Checkbox } from "src/components/ui/checkbox";

export default function Importacao() {
  const [isSending, setIsSending] = useState(false);
  const { toast } = useToast();
  const { user } = useAuth();

  const formSchema = z.object({
    file: z.any().refine((files) => files?.length == 1, "Selecione um arquivo"),
    insertImport: z.boolean().default(false).optional(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      file: undefined,
      insertImport: false
    },
  });

  const fileRef = form.register("file");

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsSending(true);
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", values.file[0]);

    await stockService
      .importStocks(user ? user.userId : null, values?.insertImport , formData, token)
      .then((res) => {
        toast({
          variant: "success",
          description: "Arquivo importado com sucesso",
        });
        setIsSending(false);
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          description:
            "Houve um erro ao enviar o arquivo. Verifique o formato do arquivo e tente novamente",
        });
        console.error("Error ao enviar o arquivo:", error);
        setIsSending(false);
      });
  };
  return (
    <div className="flex flex-col">
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-6">
        <div className="grid items-center">
          <h1 className="font-semibold text-lg md:text-2xl">Importação</h1>
        </div>
        <div className="max-w-[400px] gap-2">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="file"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Arquivo (.xls ou .xlsx)</FormLabel>
                      <FormControl>
                        <Input type="file" placeholder="shadcn" {...fileRef} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="insertImport"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4 mt-4">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        Importação de inclusão
                      </FormLabel>
                      <FormDescription>
                        Ao selecionar esta opção o arquivo importado irá incluir os títulos da planilha e não substituir os títulos presentes na base.
                      </FormDescription>
                    </div>
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="bg-secondary hover:bg-secondary-foreground mt-4"
              >
                {isSending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Importar arquivo <FileSpreadsheet className="pl-2" size={27} />
              </Button>
            </form>
          </Form>
        </div>
      </main>
      <Toaster />
    </div>
  );
}
